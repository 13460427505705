import React from 'react';
import { Link } from 'components';
import { Goal } from 'components/content';

const goals: Goal[] = [
  {
    id: 'm6-friends-goal1',
    title: 'Encourage your teen to do something kind for a friend or family member.',
    content: (
      <>
        See <Link href="/m6-friends/06-good-social-skills">Good social skills</Link>.
      </>
    ),
  },
  {
    id: 'm6-friends-goal2',
    title: 'Encourage your teen to join a social activity that they might enjoy doing.',
    content: (
      <>
        See <Link href="/m6-friends/03-social-situations">A Range of social situations</Link>.
      </>
    ),
  },
  {
    id: 'm6-friends-goal3',
    title: 'Ask your teen if they would like to invite their friends over to your home.',
    content: (
      <>
        See <Link href="/m6-friends/04-you-and-their-friends">You and your teenager’s friends</Link>.
      </>
    ),
  },
  {
    id: 'm6-friends-goal4',
    title: 'Take the opportunity to talk with your teenager if they are having friendship troubles.',
    content: (
      <>
        See <Link href="/m6-friends/07-friendship-troubles">Friendship troubles</Link>.
      </>
    ),
  },
];

export default goals;
