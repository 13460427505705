import './firebaseApp';
import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import { store, watchAuth } from './app';
import { theme } from './theme';
import './global.css';

export type PageRootProps = {
  children: React.ReactNode;
};

export default function PageProvider(props: PageRootProps) {
  React.useEffect(() => watchAuth(store), []);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </Provider>
  );
}
