import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { RootState } from './types';
import auth from './authSlice';
import profile from './profileSlice';

export const store = configureStore({
  reducer: combineReducers({ profile, auth }),
  middleware: getDefaultMiddleware({ serializableCheck: false }),
});

export const selectAuth = (state: RootState) => state.auth;

export const selectProfile = (state: RootState) => state.profile;
