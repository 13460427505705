import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { Avatar, Grid, Box, Button, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { createStyles, makeStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LinkIcon from '@material-ui/icons/LinkRounded';
import PhoneIcon from '@material-ui/icons/Contacts';
import AboutIcon from '@material-ui/icons/InfoRounded';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { selectAuth, selectProfile } from 'app';
import { signout } from 'app/authSlice';
import { getAvatar, getDisplayName } from 'app/helpers';
import logo from '../../assets/logo_white.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      flex: 1,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },

    toolbar: {
      ...theme.mixins.toolbar,
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      paddingLeft: theme.spacing(2),
      justifyContent: 'flex-start',
      alignItems: 'center',
      '&>img': {
        opacity: 0.9,
      },
    },

    userBar: {
      // backgroundColor: theme.palette.primary.main,
      color: 'rgba(255, 255, 255, 0.8)',
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    avatar: {
      width: 100,
      height: 100,
      backgroundColor: 'rgba(255,255,255,.2)',
      marginBottom: theme.spacing(1),
    },

    actionBtn: {
      marginTop: theme.spacing(1),
    },

    itemText: {
      textAlign: 'center',
    },
  }),
);

export function AppDrawer() {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userInfo, authenticated } = useSelector(selectAuth);
  const { data: profile } = useSelector(selectProfile);

  const handleSignout = () => {
    navigate('/');
    dispatch(signout());
  };

  const selected = (path: string) => {
    return location.pathname.startsWith(path);
  };

  return (
    <nav className={classes.root}>
      <div className={classes.toolbar}>
        <img src={logo} width={120} height={38} />
      </div>

      {profile && (
        <section className={classes.userBar}>
          <Avatar className={classes.avatar} src={getAvatar(profile.user, userInfo)}></Avatar>
          <Typography variant="caption" color="inherit" gutterBottom>
            {userInfo?.email}
          </Typography>
          <Typography variant="subtitle1" color="inherit" gutterBottom>
            {getDisplayName(profile.user)}
          </Typography>
        </section>
      )}

      {authenticated && (
        <Grid container justify="center">
          <Button className={classes.actionBtn} color="secondary" variant="contained" onClick={handleSignout}>
            Sign out
          </Button>
        </Grid>
      )}

      <Box>
        <List>
          <DrawerLinkItem icon={<HomeIcon />} text="Home" href="/" />
          {authenticated ? (
            <DrawerLinkItem
              icon={<DashboardIcon />}
              text="Dashboard"
              href="/dashboard"
              selected={selected('/dashboard')}
            />
          ) : (
            <DrawerLinkItem icon={<VpnKeyIcon />} text="Sign in" href="/auth/signin" />
          )}
          <DrawerLinkItem icon={<LinkIcon />} text="Useful Resources" href="/resources" />
          <DrawerLinkItem icon={<AboutIcon />} text="About Us" href="/about" />
          <DrawerLinkItem icon={<PhoneIcon />} text="Contact Us" href="/contact" />
          {authenticated && <DrawerLinkItem icon={<MenuBookIcon />} text="Appendix" href="/appendix" />}
        </List>
      </Box>
    </nav>
  );
}

export const DrawerLinkItem = withStyles((theme) =>
  createStyles({
    itemContent: {
      color: 'rgba(255,255,255,.5)',
    },
    selected: {
      color: 'rgba(255,255,255,1)',
    },
  }),
)((props: { icon?: React.ReactElement; text: string; href: string; selected?: boolean } & WithStyles) => {
  const { icon, text, href, selected, classes } = props;
  return (
    <ListItem
      className={classes.itemContent}
      classes={{ selected: classes.selected }}
      href={href}
      onClick={() => navigate(href)}
      selected={selected}
      button>
      {icon && <ListItemAvatar>{icon}</ListItemAvatar>}
      <ListItemText primary={text} />
    </ListItem>
  );
});
