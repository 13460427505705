import React from 'react';
import { navigate } from 'gatsby';
import { ProfileState, selectProfile } from 'app';
import { useSelector } from 'react-redux';

export function useProfile(): ProfileState {
  const profile = useSelector(selectProfile);
  React.useEffect(() => {
    if (profile.fetched && profile.data === null) {
      //user has not created profile yet
      navigate('/settings/createProfile', { replace: true, state: { emptyProfile: true } });
    }
  }, [profile]);
  return profile;
}
