import React from 'react';
import { Link } from 'components';
import { Goal } from 'components/content';

const goals: Goal[] = [
  {
    id: 'm10-seeking-help-goal1',
    title: 'Check out the list of resources for parents and teens on our Useful Resources page.',
    content: (
      <>
        Take your time and find out more about these online <Link href="/resources">resources</Link>.
      </>
    ),
  },
  {
    id: 'm10-seeking-help-goal2',
    title:
      "Think about what factors in your teen's life might increase their chances of developing depression or clinical anxiety.",
    content: (
      <>
        See{' '}
        <Link href="/m10-seeking-help/03-risk-factors">
          What factors can increase the risk of depression and clinical anxiety in teenagers
        </Link>
        . Have a think about what you might be able to do to reduce the impact of these factors on your teenager.
      </>
    ),
  },
  {
    id: 'm10-seeking-help-goal3',
    title:
      'If you suspect your teen may be developing depression or clinical anxiety, talk with them about it this week.',
    content: (
      <>
        Offer to arrange for them to speak to a professional (e.g. counsellor, family doctor) if they wish. See{' '}
        <Link href="/m10-seeking-help/04-depression-signs">Depression: What should I look for</Link>,{' '}
        <Link href="/m10-seeking-help/05-anxiety-signs">Clinical anxiety: What should I look for</Link>,{' '}
        <Link href="/m10-seeking-help/06-what-is-normal">So, what is 'normal'</Link>, and{' '}
        <Link href="/m10-seeking-help/07-what-should-I-do">Things aren’t okay – what should I do</Link>.
      </>
    ),
  },
  {
    id: 'm10-seeking-help-goal4',
    title: 'Encourage your teenager to try out one of the self-help strategies.',
    content: (
      <>
        See{' '}
        <Link href="/m10-seeking-help/10-previous-depression">What if my teenager has previously had depression</Link>.
      </>
    ),
  },
];

export default goals;
