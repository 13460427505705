import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';
import cyan from '@material-ui/core/colors/cyan';
import { RingShape } from './RingShape';

const sizes = {
  small: {
    w: 48,
    strokeWidth: 2,
  },
  medium: {
    w: 64,
    strokeWidth: 3,
  },
  large: {
    w: 100,
    strokeWidth: 5,
  },
};

const useStyles = makeStyles((theme) =>
  createStyles<string, { size: number }>({
    root: ({ size }) => {
      return {
        position: 'relative',
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...theme.typography.body1,
      };
    },

    icon: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  }),
);

export type RingProps = {
  size?: 'small' | 'medium' | 'large';
  percent?: number;
  children?: React.ReactNode;
  color?: string;
  trackColor?: string;
};

export function Ring(props: RingProps) {
  const { size = 'medium', children, percent = 0, color = cyan[500], trackColor = 'rgba(255,255,255,.4)' } = props;
  const { w, strokeWidth } = sizes[size];
  const classes = useStyles({ size: w });

  return (
    <div className={classes.root}>
      <SvgIcon viewBox={`0 0 ${w} ${w}`} className={classes.icon}>
        <RingShape
          cx={w / 2}
          cy={w / 2}
          r={(w - strokeWidth) / 2}
          percent={percent}
          strokeWidth={strokeWidth}
          trackColor={trackColor}
          color={color}
        />
      </SvgIcon>
      {children}
    </div>
  );
}
