import React from 'react';
import { Link } from 'components';
import { Goal } from 'components/content';

const goals: Goal[] = [
  {
    id: 'm7-health-habits-goal1',
    title: 'Cook one new healthy meal with your teenager – and enjoy!',
    content: (
      <>
        See <Link href="/m7-health-habits/03-healthy-diet">Encourage a healthy diet</Link>.
      </>
    ),
  },
  {
    id: 'm7-health-habits-goal2',
    title: 'Have a conversation with your teenager about the types of social media they use.',
    content: (
      <>
        Discuss whether they are engaging in good-quality and helpful content. See{' '}
        <Link href="/m7-health-habits/07-screentime">What about screen time</Link>.
      </>
    ),
  },
  {
    id: 'm7-health-habits-goal3',
    title: 'Engage in some form of exercise with your teenager.',
    content: (
      <>
        Help them to reach their goal of 60 minutes of physical activity a day (see Encourage daily exercise{' '}
        <Link href="/m7-health-habits/05-daily-exercise">Part I</Link> and{' '}
        <Link href="/m7-health-habits/06-build-into-life">Part II</Link>).
      </>
    ),
  },
  {
    id: 'm7-health-habits-goal4',
    title: 'Pick one of the six good sleep habits and encourage your teen to try it out for one week.',
    content: (
      <>
        See <Link href="/m7-health-habits/10-sleep-habits">Encourage good sleep habits</Link>.
      </>
    ),
  },
  {
    id: 'm7-health-habits-goal5',
    title: 'Think about where you keep alcohol or cigarettes in your house.',
    content: (
      <>
        See if your teen might have access to these substances (See{' '}
        <Link href="/m7-health-habits/12-no-alcohol-drugs">Ensure no alcohol and other drug use</Link>).
      </>
    ),
  },
];

export default goals;
