import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Button, ButtonBase, CircularProgress, IconButton, Menu, MenuItem } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import clsx from 'clsx';

import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from 'app';
import { signout } from 'app/authSlice';
import UserAvatar from '@material-ui/icons/PersonSharp';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    menuItem: {
      padding: '2px 4px',
      margin: theme.spacing(0, 2),
      ...theme.typography.button,
      borderBottomWidth: 3,
      borderBottomStyle: 'solid',
      borderBottomColor: 'transparent',
      '&.active': {
        borderBottomColor: orange[200],
      },
    },
  }),
);

const appendix = {
  label: 'Appendix',
  location: '/appendix',
};

const menus = [
  {
    label: 'Home',
    location: '/',
    exact: true,
  },
  {
    label: 'Dashboard',
    location: '/dashboard',
  },
  {
    label: 'Resources',
    location: '/resources',
  },
  {
    label: 'About Us',
    location: '/about',
  },
  {
    label: 'Contact Us',
    location: '/contact',
  },
];

export function SiteMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { authenticated } = useSelector(selectAuth);
  const [iconElm, setIconElm] = React.useState<HTMLElement | null>(null);

  const handleSignout = () => {
    navigate('/');
    dispatch(signout());
    setIconElm(null);
  };

  return (
    <nav className={classes.root}>
      {[...menus, ...(authenticated ? [appendix] : [])].map((item, index) => {
        const active = location.pathname === item.location;
        if (item.label !== 'Dashboard' || (item.label === 'Dashboard' && authenticated))
          return (
            <ButtonBase
              key={item.location}
              onClick={() => navigate(item.location)}
              className={clsx(classes.menuItem, { active })}>
              {item.label}
            </ButtonBase>
          );
      })}

      <Box ml={4}>
        {authenticated !== null ? (
          authenticated ? (
            <>
              <IconButton onClick={(e) => setIconElm(e.currentTarget)} color="inherit">
                <UserAvatar />
              </IconButton>
              <Menu id="user-menu" anchorEl={iconElm} open={Boolean(iconElm)} onClose={() => setIconElm(null)}>
                <MenuItem onClick={handleSignout}>Sign out</MenuItem>
              </Menu>
            </>
          ) : (
            <Button color="inherit" variant="outlined" onClick={() => navigate('/auth/signin')}>
              Sign in
            </Button>
          )
        ) : (
          <CircularProgress variant="indeterminate" size={16} />
        )}
      </Box>
    </nav>
  );
}
