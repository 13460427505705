import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import * as React from 'react';
export default {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  React
};