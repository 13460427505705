import {
  AppBar as MuiAppBar,
  Box,
  createStyles,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  useScrollTrigger,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import React from 'react';
import { NavPage, NavPageContnext } from './NavPage';
import MenuIcon from '@material-ui/icons/Menu';
import { AppDrawer } from './AppDrawer';
import { cyan } from '@material-ui/core/colors';
import clsx from 'clsx';
import { SiteMenu } from './SiteMenu';
import logo from '../../assets/logo.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
    },
    main: {
      display: 'block',
      width: '100%',
      height: '100%',
      overflow: 'auto',
      backgroundColor: '#fff',
    },
    topGutter: {
      ...theme.mixins.toolbar,
    },
  }),
);

export type SimplePageProps = {
  children: React.ReactNode;
  color?: string;
};

export function SitePage(props: SimplePageProps) {
  const { children } = props;
  const classes = useStyles();
  const [elm, setElm] = React.useState<HTMLElement | null>(null);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 64,
    target: elm !== null ? elm : undefined,
  });

  return (
    <div className={classes.root}>
      <NavPage drawerContent={<AppDrawer />}>
        <AppBar inverse={trigger} />
        <main className={classes.main} style={{ backgroundColor: props.color }} ref={(elm) => setElm(elm)}>
          <div className={classes.topGutter} />
          {children}
        </main>
      </NavPage>
    </div>
  );
}

const AppBar = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: cyan[700],
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeIn,
    }),
    '&.inverse': {
      backgroundColor: cyan[700],
      color: 'rgba(255, 255, 255, 0.9)',
    },
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.h6,
    fontFamily: 'Fira Sans Condensed',
    fontWeight: 500,
    color: 'inherit',
  },
}))((props: { inverse?: boolean } & WithStyles) => {
  const { classes, inverse } = props;
  const { setDrawerOpen } = React.useContext(NavPageContnext);
  return (
    <MuiAppBar className={clsx(classes.root, { inverse })} elevation={inverse ? 4 : 0}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex">
          <Hidden mdUp>
            <IconButton color="inherit" edge="start" onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.title}>
            <img src={logo} style={{ width: 32, height: 32, marginRight: 5 }} />
            <span>PARTNERS IN PARENTING</span>
          </div>
        </Box>
        <Hidden smDown>
          <SiteMenu />
        </Hidden>
      </Toolbar>
    </MuiAppBar>
  );
});

export default SitePage;
