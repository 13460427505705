import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Nav } from './Nav';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
    },
  }),
);

export const NavPageContnext = React.createContext<{
  setDrawerOpen: (open: boolean) => void;
  drawerWidth: number;
}>({
  setDrawerOpen: () => {
    throw new Error('Not implemented');
  },
  drawerWidth: 300,
});

export type NavPageProps = {
  responsive?: boolean;
  drawerContent?: React.ReactElement;
} & Pick<React.HTMLProps<HTMLElement>, 'children'>;

export function NavPage(props: NavPageProps) {
  const { drawerContent, responsive } = props;
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  return (
    <div className={classes.root}>
      <Nav width={300} open={drawerOpen} onClose={() => setDrawerOpen(false)} responsive={Boolean(responsive)}>
        {drawerContent}
      </Nav>
      <NavPageContnext.Provider value={{ setDrawerOpen, drawerWidth: 300 }}>{props.children}</NavPageContnext.Provider>
    </div>
  );
}
