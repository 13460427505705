import React from 'react';
import { Link } from 'components';
import { Goal } from 'components/content';

const goals: Goal[] = [
  {
    id: 'm5-conflict-goal1',
    title: 'Practise choosing your battles with your teen.',
    content: (
      <>
        Decide if a problem needs to be addressed. Is it a minor irritation which you can accept and let go of? (See{' '}
        <Link href="/m5-conflict/05-ground-rules">Ground rules</Link>).
      </>
    ),
  },
  {
    id: 'm5-conflict-goal2',
    title:
      'Next time you have a disagreement with your teen or another family member, take some time to calm down before trying to resolve the disagreement.',
    content: (
      <>
        See <Link href="/m5-conflict/07-remain-calm">Conflict with your teenager? Remain calm</Link>.
      </>
    ),
  },
  {
    id: 'm5-conflict-goal3',
    title: 'Practise using assertive communication with your teenager.',
    content: (
      <>
        See the tips on <Link href="/m5-conflict/10-communication-styles">Communication styles</Link>.
      </>
    ),
  },
  {
    id: 'm5-conflict-goal4',
    title: 'Reflect on how you yourself were treated by your parents.',
    content: (
      <>
        Do you see your parents in your own behaviour? How did you feel as a teenager being parented this way? Pick one
        positive parenting behaviour you will do more of this week, and one negative behaviour you will do less of (See{' '}
        <Link href="/m5-conflict/12-carry-on-loving-them">No end to the arguments</Link>).
      </>
    ),
  },
];

export default goals;
