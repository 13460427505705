import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import cyan from '@material-ui/core/colors/cyan';
import blue from '@material-ui/core/colors/blue';
export const primaryColour = '#04BEBE';
export const secondaryColour = '#0461BE';
export const primaryDark = '#008c91';
export const textHighlight = '#00245E';
export const buttonColour = '#F99746';

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: cyan[800],
        contrastText: '#fff',
      },
      secondary: { main: blue[700] },
    },
    // typography: {
    //   fontFamily: amikoFontFamily,
    //   fontWeightBold: 700,
    //   fontWeightLight: 400,
    //   fontWeightMedium: 600,
    //   fontWeightRegular: 400,
    //   body1: {
    //     letterSpacing: -1,
    //     lineHeight: 1.7,
    //   },
    //   body2: {
    //     letterSpacing: -1,
    //     lineHeight: 1.7,
    //   },
    //   button: {
    //     letterSpacing: 1,
    //     lineHeight: 1.7,
    //   },
    //   caption: {
    //     lineHeight: 1.7,
    //   },
    //   h1: {
    //     color: primaryColour,
    //     fontSize: '4rem',
    //     fontWeight: 300,
    //     letterSpacing: -2,
    //     lineHeight: 1,
    //   },
    //   h2: {
    //     fontSize: '3rem',
    //     fontWeight: 300,
    //     letterSpacing: -2,
    //     lineHeight: 1,
    //   },
    //   h3: {
    //     fontSize: '2.5rem',
    //     fontWeight: 300,
    //     letterSpacing: -2,
    //     lineHeight: 1,
    //   },
    //   h4: {
    //     fontSize: '2rem',
    //     fontWeight: 300,
    //     letterSpacing: -2,
    //     lineHeight: 1,
    //   },
    //   h5: {
    //     fontSize: '1.75rem',
    //     fontWeight: 400,
    //     letterSpacing: -2,
    //     lineHeight: 1,
    //   },
    //   h6: {
    //     fontSize: '1.25rem',
    //     fontWeight: 600,
    //     letterSpacing: -2,
    //     lineHeight: 1.7,
    //   },
    //   overline: {
    //     letterSpacing: 1,
    //     lineHeight: 1.7,
    //   },
    // },
  }),
);
