import React from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { selectAuth, selectProfile } from 'app';

import { verificationInvalid } from 'app/helpers';

export type ProtectedProps = {
  redirect?: string;
  children: React.ReactChild;
};

export function Protected(props: ProtectedProps) {
  const { redirect = '/auth/signin', children } = props;
  const { userInfo, authenticated } = useSelector(selectAuth);
  const profile = useSelector(selectProfile);
  const slug = profile.data?.user.slug;
  const expire = verificationInvalid(userInfo?.metadata?.creationTime, userInfo?.emailVerified);

  React.useEffect(() => {
    if (authenticated === false) navigate(redirect, { replace: true });
    else {
      if (userInfo && userInfo!.uid === process.env.COACH_UID)
        if (slug) navigate(`${slug}?minimize`, { replace: true });

      // Check whether user's verification email has expired. If already expired,
      // prompt the user to verify the email first before accessing the program
      if (expire) navigate('/auth/verifyEmail', { replace: true });
    }
  }, [authenticated, slug]);

  return authenticated && !expire ? <>{children}</> : null;
}
