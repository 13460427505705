import React from 'react';

/**
 * Ring shape component
 */
export type RingShapeProps = React.HTMLAttributes<SVGGElement> & {
  color: string;
  trackColor: string;
  cx: number;
  cy: number;
  r: number;
  strokeWidth: number;
  percent: number;
};

export function RingShape(props: RingShapeProps) {
  const { cx, cy, r, percent, strokeWidth, trackColor, color, ...others } = props;
  const end = Math.min(percent * (Math.PI * 2) - Math.PI / 2, (3 * Math.PI) / 2 - 0.0001);
  const start = -Math.PI / 2;
  const partialCircle = (cx: number, cy: number, r: number, start: number, end: number) => {
    const length = Math.max(0, end - start);
    const fromX = r * Math.cos(start) + cx;
    const fromY = r * Math.sin(start) + cy;
    const toX = r * Math.cos(end) + cx;
    const toY = r * Math.sin(end) + cy;
    const large = Math.abs(length) <= Math.PI ? '0' : '1';
    const sweep = length < 0 ? '0' : '1';
    return [
      ['M', fromX, fromY],
      ['A', r, r, 0, large, sweep, toX, toY],
    ]
      .flat()
      .join(' ');
  };

  return (
    <g strokeLinecap="round" fill="transparent" {...others}>
      <circle cx={cx} cy={cy} r={r} strokeWidth={strokeWidth} stroke={trackColor} />
      {percent > 0 && <path d={partialCircle(cx, cy, r, start, end)} stroke={color} strokeWidth={strokeWidth} />}
    </g>
  );
}
