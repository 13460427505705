import { createSlice, PayloadAction, Store, createAction } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import firebase from 'firebase/app';
import { request, failure, idle } from './helpers';
import { AuthState, UserInfo, AppThunk, RootState, Credential, UserProfile, UserTopic, UserSurvey } from './types';
import update from 'immutability-helper';
import { updateProfile } from 'app/profileSlice';

export type ProfileData = {
  user: UserProfile;
  topics: Record<string, UserTopic>;
  surveys: Record<string, UserSurvey>;
};

export type AuthenticatedPayload = {
  userInfo: UserInfo;
  profileData: ProfileData | null;
};

export const authenticated = createAction<AuthenticatedPayload>('authenticated');

export const notAuthenticated = createAction('notAuthenticated');

export type RegisterInput = {
  email: string;
  password: string;
};

export type ActivateInput = {
  token: string;
  email: string;
  password: string;
};

export const initialState: AuthState = {
  authenticated: null,
  userInfo: null,
  status: null,
};

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    request: request('status'),
    failure: failure('status'),
    idle: idle('status'),
    mergeUserInfo(state, action: PayloadAction<Partial<UserInfo>>) {
      state.userInfo = update(state.userInfo, { $merge: action.payload });
    },
  },
  extraReducers: {
    authenticated(state, action: PayloadAction<AuthenticatedPayload>) {
      const { userInfo } = action.payload;
      state.authenticated = true;
      state.userInfo = userInfo;
      state.status = null;
    },

    notAuthenticated(state) {
      state.authenticated = false;
      state.userInfo = null;
      state.status = null;
    },
  },
});

export { actions };

const createSeq = () => {
  let seq = 0;
  return function next() {
    return ++seq;
  };
};

const seq = createSeq();

/**
 * Sign in
 *
 * @param data
 * @param option
 */
export const signin =
  (data: Credential & { slug?: string }): AppThunk =>
  async (dispatch) => {
    dispatch(actions.request());
    try {
      const { email, password, rememberMe, slug } = data;
      const persistence = rememberMe ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION;
      await firebase.auth().setPersistence(persistence);
      const userRecord = await firebase.auth().signInWithEmailAndPassword(email, password);

      if (slug) {
        await firebase.firestore().doc(`users/${userRecord.user!.uid}`).update({ slug });
        const user = (await firebase.firestore().doc(`users/${userRecord.user!.uid}`).get()).data()!;
        dispatch(updateProfile(user));
      }

      firebase.analytics().logEvent('login', { method: 'email' });
    } catch (err) {
      dispatch(actions.failure((err as Error).message || 'Failed to signin'));
    }
  };

/**
 * Signin facebook
 *
 * @returns void
 */
export const signinFacebook = (): AppThunk => async (dispatch) => {
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope('email');
  provider.addScope('public_profile');
  provider.addScope('groups_access_member_info');

  try {
    await firebase.auth().signInWithPopup(provider);
  } catch (err) {
    dispatch(
      actions.failure(
        (err as any).code === 'auth/account-exists-with-different-credential'
          ? `You have already registered with your email: ${
              (err as any).email
            }. Please sign in using your email and password.`
          : 'Failed to signin with Facebook',
      ),
    );
  }
};

export const signout = (): AppThunk => async () => {
  await firebase.auth().signOut();

  firebase.analytics().logEvent('sign_out');
};

export const signup =
  (data: RegisterInput, onSuccess?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      const { email, password } = data;
      const auth = firebase.auth();
      const analytics = firebase.analytics();

      dispatch(actions.request());
      await auth.createUserWithEmailAndPassword(email, password);

      // Send verification email to the user with a continue URL back to the site
      await firebase.auth().currentUser?.sendEmailVerification(getActionCodeSettings());

      analytics.logEvent('signup');
      dispatch(actions.idle());
      onSuccess && onSuccess();
    } catch (err) {
      dispatch(actions.failure((err as Error).message || 'Failed to register'));
    }
  };

export const resetPassword =
  (email: string, success?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      const auth = firebase.auth();
      const analytics = firebase.analytics();

      dispatch(actions.request());
      await auth.sendPasswordResetEmail(email);
      analytics.logEvent('reset_password');
      dispatch(actions.idle());
      success && success();
    } catch (err) {
      dispatch(actions.failure((err as Error).message || 'Failed to reset password'));
    }
  };

export const changePassword =
  (newPassword: string, success?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      const auth = firebase.auth();

      dispatch(actions.request());
      await auth.currentUser?.updatePassword(newPassword);
      dispatch(actions.idle());
      success && success();
    } catch (err) {
      console.error(err);
    }
  };

export const changeEmail =
  (newEmail: string, success?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      const auth = firebase.auth();

      dispatch(actions.mergeUserInfo({ email: newEmail }));
      dispatch(actions.request());

      await auth.currentUser?.updateEmail(newEmail);
      await firebase.auth().currentUser?.sendEmailVerification(getActionCodeSettings());
      await firebase.auth().signOut();

      dispatch(actions.idle());
      success && success();
    } catch (err) {
      dispatch(
        actions.failure(
          (err as any).code === 'auth/requires-recent-login'
            ? 'Updating email is sensitive and requires recent authentication. Log in again before retrying this request.'
            : (err as any).message,
        ),
      );
    }
  };

export const verifyEmail =
  (email?: string, success?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      const auth = firebase.auth();
      const functions = firebase.functions();
      dispatch(actions.request());

      // If email provided on ther verifyEmail screen, send verification email via the
      // HTTP callable function since the email needs to be updated to the user account first
      // by the admin SDK
      if (email) {
        await functions.httpsCallable('sendVerificationEmail')({ email });
      } else {
        await auth.currentUser?.sendEmailVerification(getActionCodeSettings());
      }

      dispatch(actions.idle());
      success && success();
    } catch (err) {
      console.error(err);
    }
  };

export const updatePhotoURL =
  (photoURL: string): AppThunk =>
  async (dispatch) => {
    try {
      const auth = firebase.auth();

      dispatch(actions.mergeUserInfo({ photoURL }));
      dispatch(actions.request());
      await auth.currentUser?.updateProfile({ photoURL });
      dispatch(actions.idle());
    } catch (err) {
      console.error(err);
    }
  };

export const revokeConsent = (): AppThunk => async (dispatch) => {
  try {
    await firebase.functions().httpsCallable('revokeConsent')();
    await firebase.auth().signOut();
    firebase.analytics().logEvent('revoke');
  } catch (err) {
    console.error(err);
  }
};

/**
 * Watch auth status
 * @param store
 */
export function watchAuth(store: Store<RootState>) {
  const loadUserData = async (firebaseUser: firebase.User): Promise<string> => {
    const firestore = firebase.firestore();

    const userInfo: UserInfo = pick(
      firebaseUser,
      'uid',
      'displayName',
      'email',
      'phoneNumber',
      'photoURL',
      'providerId',
      'providerData',
      'emailVerified',
      'metadata',
    );

    let profileData: ProfileData | null = null;
    const uid = userInfo.uid;

    const userDoc = await firestore.collection(`users`).doc(uid).get();
    if (userDoc.exists) {
      const user = userDoc.data() as UserProfile;
      const topicsSnapshot = await firestore.collection(`users/${uid}/topics`).get();
      const topics = topicsSnapshot.docs.reduce<Record<string, UserTopic>>((topics, doc) => {
        return { ...topics, [doc.id]: doc.data() as UserTopic };
      }, {});

      const surveySnapshot = await firestore.collection(`users/${uid}/surveys`).get();
      const surveys = surveySnapshot.docs.reduce<Record<string, UserSurvey>>((surveys, doc) => {
        return { ...surveys, [doc.id]: doc.data() as UserSurvey };
      }, {});
      profileData = {
        user,
        topics,
        surveys,
      };
    }

    store.dispatch(
      authenticated({
        userInfo,
        profileData,
      }),
    );

    return userInfo.uid;
  };

  return firebase.auth().onAuthStateChanged((userInfo) => {
    if (userInfo) {
      loadUserData(userInfo).then((uid) => {
        firebase.analytics().setUserId(uid);
      });
    } else {
      store.dispatch(notAuthenticated());
    }
  });
}

export function getActionCodeSettings() {
  return {
    url: process.env.GATSBY_FIREBASE_AUTH_URL!,
  };
}

export default reducer;
