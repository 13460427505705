import get from 'lodash/get';
import { createAction, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import firebase from 'firebase/app';
import { request, failure, idle, isFacebookUser } from './helpers';
import {
  AppThunk,
  ProfileState,
  TopicPlanItem,
  UserInfo,
  UserProfile,
  UserSurvey,
  UserTopic,
  UserTopicEntry,
} from './types';
import { AuthenticatedPayload } from './authSlice';
import { SurveySymptons } from 'components/feeback';

export const fetched = createAction<SetProfileData>('fetched');

export type SetProfileData = {
  topics: Record<string, UserTopic>;
  surveys: Record<string, UserSurvey>;
  user: UserProfile;
};

export const initialState: ProfileState = {
  fetched: false,
  status: null,
  data: null,
};

const { reducer, actions } = createSlice({
  name: 'profile',
  initialState,
  extraReducers: {
    authenticated(state, action: PayloadAction<AuthenticatedPayload>) {
      const { profileData } = action.payload;
      state.data = profileData;
      state.fetched = true;
    },

    notAuthenticated(state) {
      state.fetched = true;
      state.data = null;
    },
  },
  reducers: {
    request: request('status'),

    failure: failure('status'),

    idle: idle('status'),

    fetched(state, action: PayloadAction<SetProfileData>) {
      return update(state, { $merge: { data: action.payload, fetched: true } });
    },

    updateUserProfile(state, action: PayloadAction<Partial<UserProfile>>) {
      return update(state, {
        data: { user: { $merge: action.payload } },
      });
    },

    updateSurveys(state, action: PayloadAction<Record<string, UserSurvey>>) {
      return update(state, {
        data: { surveys: { $merge: action.payload } },
      });
    },

    updateTopics(state, action: PayloadAction<Record<string, UserTopic>>) {
      return update(state, {
        data: { topics: { $merge: action.payload } },
      });
    },

    updateSurvey(state, action: PayloadAction<{ surveyId: string; userSurvey: Partial<UserSurvey> }>) {
      const { surveyId, userSurvey } = action.payload;
      return update(state, {
        data: { surveys: { [surveyId]: { $merge: userSurvey } } },
      });
    },

    mergeUserTopic(state, action: PayloadAction<{ topicId: string; userTopic: Partial<UserTopic> }>) {
      const { topicId, userTopic } = action.payload;
      if (!userTopic) return;

      return update(state, {
        data: { topics: { [topicId]: { $merge: userTopic } } },
      });
    },

    mergeTopicEntry(
      state,
      action: PayloadAction<{ topicId: string; entryKey: string; data: Partial<UserTopicEntry> }>,
    ) {
      const { topicId, entryKey, data } = action.payload;
      const entryData = { ...get(state.data, ['topics', topicId, 'entries', entryKey]), ...data };

      return update(state, {
        data: {
          topics: {
            [topicId]: {
              entries: { $merge: { [entryKey]: entryData } },
            },
          },
        },
      });
    },
  },
});

export default reducer;

export { actions };

const wrapStatus = (dispatch: Dispatch, erroMessage: string = 'Error') => {
  return async (effect: () => Promise<void>) => {
    dispatch(actions.request());
    try {
      await effect();
      dispatch(actions.idle());
    } catch (error) {
      console.error(error);
      dispatch(actions.failure('Failed to fetch profile'));
    }
  };
};

export const fetchProfile = (): AppThunk => async (dispatch) => {
  await wrapStatus(
    dispatch,
    'Failed to fetch profile',
  )(async () => {
    const { uid } = firebase.auth().currentUser!;
    const firestore = firebase.firestore();

    const userDoc = await firestore.collection(`users`).doc(uid).get();
    const user = userDoc.data() as UserProfile;

    const topicsSnapshot = await firestore.collection(`users/${uid}/topics`).get();
    const topics = topicsSnapshot.docs.reduce<Record<string, UserTopic>>((topics, doc) => {
      return { ...topics, [doc.id]: doc.data() as UserTopic };
    }, {});

    const surveySnapshot = await firestore.collection(`users/${uid}/surveys`).get();
    const surveys = surveySnapshot.docs.reduce<Record<string, UserSurvey>>((surveys, doc) => {
      return { ...surveys, [doc.id]: doc.data() as UserSurvey };
    }, {});

    dispatch(actions.fetched({ topics, surveys, user }));
  });
};

export const createProfile =
  (userProfile: Partial<UserProfile>): AppThunk =>
  async (dispatch) => {
    await wrapStatus(
      dispatch,
      'Failed to create user profile',
    )(async () => {
      const auth = firebase.auth();
      const firestore = firebase.firestore();
      const contactEmail = userProfile.userContactEmail!;

      // Check whether the contact email is registered with another account
      const signInMethods = await auth.fetchSignInMethodsForEmail(contactEmail);
      if (signInMethods.length > 0 && auth.currentUser?.email !== contactEmail)
        dispatch(
          actions.failure(
            `You have already registered with your email: ${userProfile.userContactEmail}. Please sign in using your email and password.`,
          ),
        );
      else {
        const { uid, providerData } = auth.currentUser!;

        // Parse the `providerData` array
        const providerArr: UserInfo[] = [];
        for (let i = 0; i < providerData.length; i++) {
          const data = providerData[i];
          if (data !== null) providerArr.push(data);
        }

        dispatch(
          actions.fetched({
            user: userProfile as UserProfile,
            topics: {},
            surveys: {},
          }),
        );

        if (!isFacebookUser(providerArr)) {
          await firestore.collection('users').doc(uid).set(userProfile);
        } else {
          await firestore
            .collection('users')
            .doc(uid)
            .set({ ...userProfile, sendVerification: true });
        }
      }
    });
  };

export const updateProfile =
  (userProfile: Partial<UserProfile>, success?: () => void): AppThunk =>
  async (dispatch) => {
    await wrapStatus(
      dispatch,
      'Failed to create user profile',
    )(async () => {
      const auth = firebase.auth();
      const firestore = firebase.firestore();

      const { uid } = auth.currentUser!;
      dispatch(actions.updateUserProfile(userProfile));
      await firestore.collection('users').doc(uid).update(userProfile);
      success && success();
    });
  };

/**
 *  Survey flow
 */
export const startSurvey =
  (surveyId: string, currentStage?: 'survey' | 'followup'): AppThunk =>
  async (dispatch) => {
    await wrapStatus(
      dispatch,
      'Failed to start survey',
    )(async () => {
      const auth = firebase.auth();
      const firestore = firebase.firestore();
      const batch = firestore.batch();

      const { uid } = auth.currentUser!;
      const currentSurvey = await firestore.collection(`users/${uid}/surveys`).doc(surveyId).get();
      if (currentSurvey.exists) {
        return;
      }

      const userSurvey: UserSurvey = { _total: 0, _completed: 0, _step: 0 };

      if (currentStage) {
        dispatch(actions.updateUserProfile({ currentStage }));
        batch.update(firestore.collection('users').doc(uid), { currentStage });
      }

      dispatch(actions.updateSurveys({ [surveyId]: userSurvey }));
      batch.set(firestore.collection(`users/${uid}/surveys`).doc(surveyId), userSurvey);

      await batch.commit();
    });
  };

export const updateUserSurvey =
  (surveyId: string, userSurvey: Partial<UserSurvey>): AppThunk =>
  async (dispatch) => {
    const firestore = firebase.firestore();

    await wrapStatus(
      dispatch,
      'Failed to update user survey',
    )(async () => {
      const auth = firebase.auth();
      const { uid } = auth.currentUser!;

      // update survey
      dispatch(actions.updateSurvey({ surveyId, userSurvey }));
      await firestore.collection(`users/${uid}/surveys`).doc(surveyId).update(userSurvey);
    });
  };

export const topicsCompleted = (): AppThunk => async (dispatch) => {
  console.log('topics completed');
  dispatch(actions.updateUserProfile({ topicCompletedAt: firebase.firestore.Timestamp.now() }));
  await firebase.functions().httpsCallable('completed')({ scope: 'topic' });
};

export const baselineCompleted =
  (surveySymptons: SurveySymptons): AppThunk =>
  async (dispatch) => {
    dispatch(actions.updateUserProfile({ surveyCompletedAt: firebase.firestore.Timestamp.now() }));
    await firebase.functions().httpsCallable('completed')({
      scope: 'survey',
      surveySymptons,
    });
  };

export const followupCompleted =
  (surveySymptons: SurveySymptons): AppThunk =>
  async (dispatch) => {
    dispatch(actions.updateUserProfile({ followupCompletedAt: firebase.firestore.Timestamp.now() }));
    await firebase.functions().httpsCallable('completed')({
      scope: 'followup',
      surveySymptons,
    });
  };

/**
 *  Topic flow
 */
export const startTopic = (): AppThunk => async (dispatch) => {
  await wrapStatus(
    dispatch,
    'Failed to start topic',
  )(async () => {
    const firestore = firebase.firestore();
    const auth = firebase.auth();
    const { uid } = auth.currentUser!;
    const data: Partial<UserProfile> = { currentStage: 'topic' };
    dispatch(actions.updateUserProfile(data));
    await firestore.collection('users').doc(uid).update(data);
  });
};

export const createTopicPlan =
  (plan: TopicPlanItem[]): AppThunk =>
  async (dispatch) => {
    await wrapStatus(
      dispatch,
      'Failed to create plan',
    )(async () => {
      const { uid } = firebase.auth().currentUser!;
      const fireStore = firebase.firestore();
      const batch = fireStore.batch();
      const topicsRef = fireStore.collection(`users/${uid}/topics`);

      const unlockIndex = plan.findIndex((userTopic) => userTopic.mandatory);
      const userTopics = plan.reduce<Record<string, UserTopic>>((userTopics, item, idx) => {
        const { topicId, mandatory, seq, tag = '' } = item;
        const userTopic: UserTopic = {
          mandatory,
          seq,
          tag,
          locked: idx !== unlockIndex,
          entries: {},
        };
        batch.set(topicsRef.doc(topicId), userTopic);
        return { ...userTopics, [item.topicId]: userTopic };
      }, {});

      dispatch(actions.updateTopics(userTopics));
      await batch.commit();
    });
  };

export const unlockTopic =
  (topicId: string): AppThunk =>
  async (dispatch, getState) => {
    await wrapStatus(
      dispatch,
      'Failed to unlock module',
    )(async () => {
      const userTopics = getState().profile.data?.topics;
      if (!userTopics) {
        return;
      }

      const userTopic = userTopics[topicId];
      if (userTopic) {
        dispatch(actions.updateTopics({ [topicId]: { ...userTopic, locked: false } }));
        const { uid } = firebase.auth().currentUser!;
        await firebase
          .firestore()
          .collection(`users/${uid}/topics`)
          .doc(topicId)
          .update({ locked: false, unlockedAt: firebase.firestore.FieldValue.serverTimestamp() });
      }
    });
  };

export const updateUserTopic =
  (topicId: string, userTopic: Partial<UserTopic>): AppThunk =>
  async (dispatch) => {
    await wrapStatus(
      dispatch,
      'Failed to update user topic',
    )(async () => {
      const auth = firebase.auth();
      const firestore = firebase.firestore();
      const { uid } = auth.currentUser!;
      dispatch(
        actions.mergeUserTopic({
          topicId,
          userTopic,
        }),
      );
      await firestore.collection(`users/${uid}/topics`).doc(topicId).update(userTopic);
    });
  };

export const setLastVisited =
  (topicId: string, entryKey: string): AppThunk =>
  async (dispatch) => {
    const auth = firebase.auth();
    const firestore = firebase.firestore();
    if (!auth.currentUser) return;
    const { uid } = auth.currentUser!;

    dispatch(
      actions.mergeUserTopic({
        topicId,
        userTopic: { lastVisited: entryKey },
      }),
    );

    await firestore.collection(`users/${uid}/topics`).doc(topicId).update({
      lastVisited: entryKey,
    });
  };

export const updateUserTopicEntry =
  (topicId: string, entryKey: string, contentId: string, value: any): AppThunk =>
  async (dispatch) => {
    await wrapStatus(
      dispatch,
      'Failed to update user topic entries',
    )(async () => {
      const auth = firebase.auth();
      const firestore = firebase.firestore();
      const { uid } = auth.currentUser!;
      const data = { [contentId]: value };

      dispatch(
        actions.mergeTopicEntry({
          topicId,
          entryKey,
          data,
        }),
      );
      await firestore
        .collection(`users/${uid}/topics`)
        .doc(topicId)
        .update({ [`entries.${entryKey}.${contentId}`]: value });
    });
  };

export const setUserTopicEntryVisited =
  (topicId: string, entryKey: string): AppThunk =>
  async (dispatch) => {
    const auth = firebase.auth();
    const firestore = firebase.firestore();
    const { uid } = auth.currentUser!;
    const { seconds, nanoseconds } = firebase.firestore.Timestamp.now();
    dispatch(
      actions.mergeTopicEntry({
        topicId,
        entryKey,
        data: { _visited: { seconds, nanoseconds } },
      }),
    );
    await firestore
      .collection(`users/${uid}/topics`)
      .doc(topicId)
      .update({
        [`entries.${entryKey}._visited`]: firebase.firestore.FieldValue.serverTimestamp(),
      });
  };
