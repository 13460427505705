import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { Box, Button, Container, IconButton, Menu, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import UserAvatar from '@material-ui/icons/Person';
import { selectAuth } from 'app';
import { signout } from 'app/authSlice';
import { Protected } from 'components';
import { AppBar } from './AppBar';
import { NavPage } from './NavPage';
import { AppDrawer } from './AppDrawer';

const useStyles = makeStyles((theme) =>
  createStyles<string, { bgcolor?: string }>({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
    },

    main: ({ bgcolor }) => ({
      width: '100%',
      display: 'block',
      flex: 1,
      overflow: 'auto',
      backgroundColor: bgcolor || 'inherit',
    }),

    topGutter: {
      ...theme.mixins.toolbar,
    },

    container: {
      paddingBottom: theme.spacing(8),
    },
  }),
);

export type AppPageProps = {
  title: string;
  children: React.ReactNode;
  bgcolor?: string;
};

export function AppPage(props: AppPageProps) {
  const { title, children, bgcolor } = props;
  const classes = useStyles({ bgcolor });
  return (
    <Protected>
      <div className={classes.root}>
        <NavPage drawerContent={<AppDrawer />}>
          <AppBar title={title} actions={<AuthActions />} openMenu />
          <main className={classes.main}>
            <div className={classes.topGutter} />
            <Container className={classes.container} maxWidth="lg">
              <>{children}</>
            </Container>
          </main>
        </NavPage>
      </div>
    </Protected>
  );
}

export function AuthActions() {
  const dispatch = useDispatch();
  const { authenticated } = useSelector(selectAuth);
  const [iconElm, setIconElm] = React.useState<HTMLElement | null>(null);

  const handleSignout = () => {
    dispatch(signout());
    navigate('/');
  };

  if (authenticated === null) {
    return null;
  }

  if (authenticated === false) {
    return (
      <Box>
        <Button color="primary" variant="contained">
          Sign in
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <IconButton onClick={(e) => setIconElm(e.currentTarget)} color="inherit">
        <UserAvatar />
      </IconButton>
      <Menu id="user-menu" anchorEl={iconElm} open={Boolean(iconElm)} onClose={() => setIconElm(null)}>
        <MenuItem onClick={handleSignout}>Sign out</MenuItem>
      </Menu>
    </Box>
  );
}
