import React from 'react';
import { Link as MuiLink, LinkProps } from '@material-ui/core';
import { navigate } from 'gatsby';

export function Link(props: Omit<LinkProps, 'onClick'>) {
  return (
    <MuiLink
      {...props}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(props.href!);
      }}
    />
  );
}

export function ExtLink(props: LinkProps) {
  return <MuiLink target="_blank" rel="noopener" {...props} />;
}
