import React from 'react';
import { Box, Button, ButtonProps, CircularProgress } from '@material-ui/core';

export function WaitButton(props: ButtonProps & { wait?: boolean }) {
  const { wait, children, disabled, ...buttonProps } = props;
  return (
    <Button {...buttonProps} disabled={disabled || wait}>
      <Box display="flex" alignItems="center">
        {wait && <CircularProgress color="secondary" size={18} style={{ marginRight: 16 }} />}
        {children}
      </Box>
    </Button>
  );
}
