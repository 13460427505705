import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
  apiKey: process.env.GATSBY_FIREBASE_APIKEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTHDOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECTID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.GATSBY_FIREBASE_APPID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENTID,
};

for (var key in config) {
  if (!config[key]) {
    throw new Error(`Firebase has not been configured for ${process.env.NODE_ENV}, missing ${key}`);
  }
}

const app = firebase.initializeApp(config);
// console.info('use emulator', process.env.GATSBY_USE_EMULATOR);

if (process.env.GATSBY_USE_EMULATOR) {
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.functions().useEmulator('localhost', 5001);
  firebase.auth().useEmulator('http://localhost:9099');
}

export { app };
