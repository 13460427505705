import { genAvatar } from 'components/layout';
import { UserInfo, UserProfile } from '../types';

export function getDisplayName(user: UserProfile) {
  return `${user.userFirstname} ${user.userSurname}`;
}

export function getAvatar(user: UserProfile, userInfo: Partial<UserInfo> | null) {
  if (user.userFirstname) return userInfo?.photoURL || genAvatar(user.userFirstname, user.userGender);
}

export function verificationInvalid(creationTime?: string, emailVerified?: boolean) {
  let expire = false;
  if (creationTime && emailVerified !== undefined) {
    // Verify whether the user account should be locked when email is not verified yet
    if (!emailVerified) {
      // Get the hour diff for the verification timestamp
      const currentDate = new Date().getTime();
      const diffHour = (currentDate - new Date(creationTime).getTime()) / 1000 / 60 / 60;
      if (diffHour >= Number(process.env.GATSBY_HOURS_UNLOCK_UNVERIFIED || 24)) expire = true; //Hours to allow access for unverified new user
    }
  }
  return expire;
}

// Check whether the current user is a Facebook user
export function isFacebookUser(providerData: UserInfo[]) {
  let isFacebookAuth = false;
  for (let i = 0; i < providerData.length; i++) {
    if (providerData[i].providerId === 'facebook.com') isFacebookAuth = true;
  }
  return isFacebookAuth;
}
