import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

export type SeperatorProps = {
  children: React.ReactNode;
};
export const Seperator = withStyles((theme) => {
  return {
    root: {
      display: 'flex',
      margin: theme.spacing(1, 0),
      '&>hr': {
        flex: 1,
        border: 'none',
        height: 1,
        backgroundColor: theme.palette.grey[400],
      },
    },
    content: {
      margin: theme.spacing(0, 1),
      ...theme.typography.caption,
    },
  };
})((props: SeperatorProps & WithStyles) => {
  const { classes, children } = props;
  return (
    <div className={classes.root}>
      <hr />
      <div className={classes.content}>{children}</div>
      <hr />
    </div>
  );
}) as React.ComponentType<SeperatorProps>;
