import React from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';
import { Toolbar, IconButton, Typography, Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { NavPageContnext } from './NavPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<string, { responsive?: boolean; drawerWidth: number }>({
    appBar: ({ responsive, drawerWidth }) => ({
      transition: 'all 0.5s',
      [theme.breakpoints.up('lg')]: responsive
        ? {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
          }
        : undefined,
    }),
    menuButton: ({ responsive }) => ({
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: responsive
        ? {
            display: 'none',
          }
        : undefined,
    }),
    title: {
      flexGrow: 1,
    },
  }),
);

export type AppBarProps = {
  title?: string;
  extraToolbar?: React.ReactNode;
  actions?: React.ReactNode;
  responsive?: boolean;
  openMenu?: boolean;
} & MuiAppBarProps;

export function AppBar(props: AppBarProps) {
  const { title, extraToolbar, openMenu, responsive, actions, ...other } = props;
  const { setDrawerOpen, drawerWidth } = React.useContext(NavPageContnext);
  const classes = useStyles({ drawerWidth, responsive });

  return (
    <MuiAppBar className={classes.appBar} {...other}>
      <Toolbar>
        {openMenu && (
          <IconButton color="inherit" edge="start" onClick={() => setDrawerOpen(true)} className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h6" className={classes.title} noWrap>
          {title}
        </Typography>
        {actions && <Box ml={1}>{actions}</Box>}
      </Toolbar>
      {extraToolbar}
    </MuiAppBar>
  );
}
