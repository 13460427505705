module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"contents":"/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx","default":"/Users/jxie/projects/lyra/packages/web/src/components/layout/SitePage.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-mermaid"},{"resolve":"gatsby-remark-prismjs"},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"projectRoot":"/Users/jxie/projects/lyra/packages/web","configDir":".gatsby"},
    }]
